import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow"
import Checkboxrow from "../../components/Checkbox/Checkboxrow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';



export default class InventoryAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            name:"",
            value:"",
            _id:"",
            product:"",
            avgcost:"",
            expiry:"",
            productlist:[],
            loading : false,
            canfilter:false,
            required:false,
        };
        this.pathname = "inventory";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                product:edit.product && edit.product._id

            },()=>this.prepareData());
        }else{
            this.prepareData()
        }


    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("product","/direct");
        const {_id} = this.state
        if(responseuni){
            const data = responseuni.data.data;
            let productlist = []
            data.map(p =>{
                    if(_id!=p._id){
                        productlist.push( { value: p._id, label: p.name+" / "+p.sku})
                    }
                }

            )

            this.setState({
                productlist
            });
        }
    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,product,expiry,damage,stock,avgcost}= this.state;


        const data={
            product : product,
            expiry,
            damage,
            stock,
            avgcost,
            _id:_id,
        }
        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading, damage,product,productlist, stock,avgcost,expiry }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow label="product "  name="product"  defaultValue={product}  data={productlist} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="pruchase" name="stock" data={stock}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="damage/expiry" name="damage" data={damage}   changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="average cost" name="avgcost" data={avgcost}   changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="expiry" name="expiry" data={expiry} type={"date"}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


