import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Row,
    Col,
    Modal,
    DropdownItem,
    UncontrolledDropdown,
    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle, CardTitle, Label, FormGroup, CardFooter
} from "reactstrap";
import {QRCodeSVG} from 'qrcode.react';

import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";


class InventoryList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            productid:"",
            selected: [],
            dragdata:[],
            modalClassic:false,
            modalClassicCity:false,
            modalClassic2:false
        };
        this.pathname = "inventory";
        this.engine = new RequestEngine();
    }




    async handleDelete(valueid) {

        if(window.confirm("are you sure ?")){
            console.log(valueid);
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage(0);
            }
            this.props.loadingAction(false)
        }



    }

    async updateData() {
        this.props.loadingAction(true)
        const result = await this.engine.updateData(this.pathname)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }

    async updateData2() {
        this.props.loadingAction(true)
        const result = await this.engine.updateData2(this.pathname)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/inventory/edit/'+dataid,
            data
        });

    }

    handleQR(index,dataid) {
        const data = this.state.dataSource[index].data
        this.setState({
            modalClassic2:true,
            productid:data.slug
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>

                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }

    renderQRCode() {
        const {modalClassic2,productid} = this.state;
        return (<>
            <Modal
                isOpen={modalClassic2}
                toggle={this.toggleModalMapClassic2}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic2}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">QR CODE  </h4>
                </div>
                <div className="modal-body">

                    <div style={{textAlign:"center"}}>
                        <QRCodeSVG value={"https://freshvery.com/minicheckout?id="+productid} size={256} />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic2}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }


    globalActions() {

        return (<UncontrolledDropdown className="pull-left" >
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Global Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => {

                    this.updateData()
                }}>Update Data From Excel 1</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                    this.updateData2()
                }}>Update Data From Excel 2</DropdownItem>



                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Featured</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Limited</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected New</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Out Of Stock</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Active</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected InActive</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected In Season</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {
                    this.setState({
                        modalClassicCity:true
                    })
                }}>Update City</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {
                    this.setState({
                        modalClassic:true
                    })
                }}>Update Order</DropdownItem>


            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    toggleModalMapClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };
    toggleModalMapClassicCity = () => {
        this.setState({
            modalClassicCity: !this.state.modalClassicCity
        });
    };


    toggleModalMapClassic2 = () => {
        this.setState({
            modalClassic2: !this.state.modalClassic2
        });
    };








    checkstatus(item) {
        if(item.active === 0){
            return ("InActive")
        }else if(item.active === 1){
            return ("Active")
        }else if(item.status === -1){
            return ("Out Of Stock")
        }
    }



    async callPage(page=0,filtered = []) {

        this.setState({
            isLoading:true
        })
        const response = await  this.engine.postItem(this.pathname,"/list/"+page,filtered);



        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    count:response.data.data.count,
                    page:page,
                    dragdata: response.data.data.data.map((item, key) => {
                        return {
                            id: item.id,
                            title: item.name ,
                            main_picture:item.main_picture ,
                        };
                    }),
                    dataSource: response.data.data.data.map((item, key) => {

                        let remainingstock = (item?.initialstockcount?item?.initialstockcount:0)+item.stock-item?.sold-item.damage

                        if(isNaN(remainingstock)){
                            remainingstock = 0;
                        }
                        debugger
                        return {
                            id: key,
                            data:item,
                            name: item?.name ,
                            sku: item?.sku ,
                            stock: item.stock ,
                            damage: item.damage ,
                            expiry:item.expiry,
                            avgcost:item.avgcost,
                            sold: item?.sold,
                            initialstock: item.initialstockcount || 0,
                            remainingstock: remainingstock,
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }

    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
        });
    }



    render() {
        const {dataSource,isLoading,count,page} = this.state;



        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        let columns = [
            {
                Header: "Name",
                accessor: "name"
            },

            {
                Header: "Sku",
                accessor: "sku"
            },
            {
                Header: "initial stock",
                accessor: "initialstock"
            },
            {
                Header: "purchase stock",
                accessor: "stock"
            },{
                Header: "sold",
                accessor: "sold"
            },
            {
                Header: "damage/expired",
                accessor: "damage"
            },
            {
                Header: "remaining stock",
                accessor: "remainingstock"
            },


            {
                Header: "average cost",
                accessor: "avgcost"
            },

            {
                Header: "expiry",
                accessor: "expiry"
            },


            {
                Header: "Transaction Date",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                draggable:true
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.props.history.push('/admin/'+this.pathname+'/upload')
                                    }>
                                        Upload Inventory
                                    </Button>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.props.history.push('/admin/'+this.pathname+'/add')
                                    }>
                                        Add Inventory
                                    </Button>

                                    <Button color="primary" className="pull-right" onClick={async () =>
                                        await this.engine.exportCSV("inventory", "/export/all")
                                    }>
                                        Export Inventory
                                    </Button>

                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        onPageChange={(pageIndex) => {
                                            console.log(pageIndex)
                                        }}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        sortable={true}
                                        rowsText={"xxx"}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(InventoryList);
